import React, { useState, useEffect } from "react";
import {
  Layout,
  FormLayout,
} from "@shopify/polaris";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../axios";
import Cookie from "js-cookie";
import "@zendeskgarden/react-pagination/dist/styles.css";
import { useParams } from "react-router-dom";
import "react-image-lightbox/style.css";
import Trackings from "./DashboardComponents/Trackings";
import Shipping from "./DashboardComponents/Shipping/Shipping";
import History from "./DashboardComponents/History";
import Orders from "./DashboardComponents/Orders";
import TicketsUser from "../CMSTicketing/TicketsPerUser/tickets-user.component";
import GuestLayout from "../components/UserLayout/GuestLayout";
import DispatchDetails from '../Payments/DispatchDetails';
import moment from 'moment/moment';
import { sortBatches } from '../util/helpers';
import { DispatchColorPattern } from '../util/constants';

export default function GuestDashboard({pageTitle}) {
  let { id } = useParams();
  const [status, setStatus] = useState("");
  const [planType, setPlanType] = useState("");
  const [profileClinic, setProfileClinic] = useState('')
  const [dispatchLoading, setDispatchLoading] = useState(false);
  const [newDispatchData, setNewDispatchData] = useState([]);
  const colors = DispatchColorPattern;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeId, setActiveId] = useState('')

  const getDispatches = async () => {
    try {
      setDispatchLoading(true);
      const response = await axios.get(`/admin/v1/users/${id}/dispatch`, {
        headers: {
          Authorization: `Bearer ${Cookie.get('token')}`,
        },
      });
      if (response.status === 200) {
        setNewDispatchData(response.data);
      }
    } catch (error) {
      console.log('Error in get dispatches api', error);
    } finally {
      setDispatchLoading(false);
    }
  };

  const formatReceivedData = (data) => {
    if (data) {
      const localColorMapping = {};
      let colorIndex = 0;
      let active = false;
      data.forEach((batch) => {
        // Assigning Colors
        if (!localColorMapping[batch.delivery]) {
          localColorMapping[batch.delivery] = colors[colorIndex];
          colorIndex = (colorIndex + 1) % colors.length;
        }
        batch.color = localColorMapping[batch.delivery] || "#fff";
        if (!active && batch.target_dispatch_date) {
          const productionTime = moment(batch.target_dispatch_date).subtract(
            21,
            "days",
          );
          if (
            moment().isAfter(productionTime) &&
            batch.actual_receipt_date === null &&
            !batch.disabled
          ) {
            batch.active = true;
            active = true;
          } else {
            batch.active = false;
          }
        }
      });
      data.sort(sortBatches);
      return data;
    }
  };
  const scrollSmoothly = (id) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      setActiveId(id)

    }
  }

  const [openSideNav, setOpenSideNav] = useState(true)
  const ontoggleNav = () => {
    setOpenSideNav(!openSideNav);
  };
  const renderSection = (anchor, title = false) => {
    if (openSideNav) {
      if (title) {
        return <span><strong>{anchor}</strong></span>
      }
      return anchor
    } else {
      return ''
    }

  }



  return (
    <GuestLayout
      pageTitle={pageTitle}
      scrollSmoothly={scrollSmoothly}
      activeId={activeId}
      renderSection={renderSection}
      ontoggleNav={ontoggleNav}
      openSideNav={openSideNav}
      setOpenSideNav={setOpenSideNav}
    >
      <FormLayout>
        <ToastContainer />
        <Layout>
          {String(Cookie.get("userType")) !== "50" && (
              <div id="shipping" style={{ width: "100%" }}>
                <Shipping
                    planType={planType}
                    pageTitle={pageTitle}
                    status={status}
                    userId={id}
                />
              </div>
          )}
          {String(Cookie.get("userType")) !== "50" && (
            <div id="paymentdetails" style={{ width: "100%" }}>
              <DispatchDetails
                loading={dispatchLoading}
                formatReceivedData={formatReceivedData}
                getDispatches={getDispatches}
                newDispatchData={newDispatchData}
                handleClickScroll={scrollSmoothly}
                markAsAlignersInProduction={null}
                message={""}
                planType={planType}
                pageTitle={pageTitle}
                status={status}
                clinicName={profileClinic}
                clinicStatus={""}
                markAlignerInProductionLoading={false}
                isRetainerOnly={false}
                purchasedAlignerKit={false}
              />
            </div>
          )}
          <div id="trackings" style={{ width: "100%" }}>
            {String(Cookie.get("userType")) !== "50" && <Trackings />}
          </div>
          <div id="payments" style={{ width: "100%" }}>
            {String(Cookie.get("userType")) !== "50" && <Orders userId={id} />}
          </div>

          <div id="tickets" style={{ width: "100%" }}>
            <TicketsUser />
          </div>
          <div id="history" style={{ width: "100%" }}>
            {String(Cookie.get("userType")) !== "50" && <History />}
          </div>
        </Layout>
      </FormLayout>
    </GuestLayout>
  );

}
