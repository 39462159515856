import React, { useState } from "react";
import axios from "../../../axios";
import Cookie from "js-cookie";
import EllipsisText from "react-ellipsis-text";
import { Thumbnail } from "@shopify/polaris";
import moment from "moment/moment";
import TeleconsultationCommentsTable from "./teleconsultation-comments-table.component";

function TeleconsultationComments({ userId }) {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);

  function handleShowLargeImage(images, key) {
    setIsOpen(!isOpen);
    setPhotoIndex(key);
    setImagesArray([...images]);
  }

  const fetchComments = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `admin/v1/users/${userId}/smile-plans/comments`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        },
      );
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          const { data } = response.data;
          setComments(
            data.map((item, key) => [
              item.plan_name,
              item.comment && (
                <EllipsisText text={item.comment} length={1000} />
              ),
              <div style={{ display: "flex", gap: "10px" }}>
                {item.images_url?.length > 0 &&
                  item.images_url.map((image, key) => (
                    <div
                      onClick={() => handleShowLargeImage(item.images_url, key)}
                    >
                      <Thumbnail
                        size="medium"
                        source={image.image ? image.image : ""}
                      />
                    </div>
                  ))}
              </div>,
              moment(item.created_at)
                .add(2, "hours")
                .format("YYYY-MM-DD hh:mm:ss"),
            ]),
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TeleconsultationCommentsTable
      fetchComments={fetchComments}
      comments={comments}
      photoIndex={photoIndex}
      isOpen={isOpen}
      imagesArray={imagesArray}
      setIsOpen={setIsOpen}
      loading={loading}
    />
  );
}

export default TeleconsultationComments;
