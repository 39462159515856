import { Card, DataTable, TextStyle } from "@shopify/polaris";
import React, { useEffect } from "react";
import Lightbox from "react-awesome-lightbox";

function TeleconsultationCommentsTable({
  comments,
  photoIndex,
  isOpen,
  imagesArray,
  setIsOpen,
  fetchComments,
  loading,
}) {
  useEffect(() => {
    void fetchComments();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading"></div>
      </div>
    );
  }

  if (comments.length === 0) {
    return <> </>;
  }

  return (
    <div className="screening-container">
      <h1 className="screening-title">COMMENTS</h1>
      <Card sectioned>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">Treatment Plan</TextStyle>,
            <TextStyle variation="strong">Comment</TextStyle>,
            <TextStyle variation="strong">Additional Images</TextStyle>,
            <TextStyle variation="strong">Created At</TextStyle>,
          ]}
          rows={comments}
          verticalAlign="middle"
        />
      </Card>
      {isOpen && imagesArray?.length && (
        <Lightbox
          startIndex={photoIndex}
          onClose={() => setIsOpen(false)}
          images={imagesArray.map((val) => val.image !== null && val.image)}
        ></Lightbox>
      )}
    </div>
  );
}

export default TeleconsultationCommentsTable;
