import React, { useCallback, useState, useEffect, createRef } from "react";
import {
  Modal,
  Checkbox,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Card,
  PageActions,
  InlineError,
  TextContainer,
} from "@shopify/polaris";
import axios from "../../../axios";
import Cookie from "js-cookie";
import history from "../../../history";
import { useLocation, useParams } from "react-router-dom";
import TreatmentPlan from "./TreatmentPlan";
import Select2 from "react-dropdown-select";
import { IPR } from "./IPR";
import SuperimposedImages from "./SuperimposedImages";
import Comments from "./Comments";
import RichTextEditor from "react-rte";
import Loading from "../../../components/Loading/loading";
import UserHeader from "../../../components/UserLayout/user-header";
import TPAccordion from "../../../components/TPAccordion/TPAccordion";
import TpActionButtons from "./TpActionButtons";
import Profile from "./Profile";
import TpDesigner from "./TpDesigner";
import TpApproval from "./TpApproval";
import ThreeDSimulation from "./ThreeDSimulation";
import RtpMca from "./RtpMca";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import TreatmentPDFEn from "./TreatmentPDFEn";
import TPR1 from "../../../img/TPR-icons-13.png";
import TPR2 from "../../../img/TPR-icons-14.png";
import TPR3 from "../../../img/TPR-icons-15.png";
import TPR4 from "../../../img/TPR-icons-16.png";
import spacingEn1 from "../../../img/cases-en/spacing-1.jpg";
import spacingEn2 from "../../../img/cases-en/spacing-2.png";
import spacingEn3 from "../../../img/cases-en/spacing-3.png";
import crowdingEn1 from "../../../img/cases-en/crowding-1.png";
import crowdingEn2 from "../../../img/cases-en/crowding-2.png";
import crowdingEn3 from "../../../img/cases-en/crowding-3.jpg";
import protrusionEn1 from "../../../img/cases-en/protrusion-1.jpg";
import protrusionEn2 from "../../../img/cases-en/protrusion-2.jpg";
import protrusionEn3 from "../../../img/cases-en/protrusion-3.png";
import spacingAr1 from "../../../img/cases-ar/spacing-1.png";
import spacingAr2 from "../../../img/cases-ar/spacing-2.png";
import spacingAr3 from "../../../img/cases-ar/spacing-3.png";
import crowdingAr1 from "../../../img/cases-ar/crowding-1.png";
import crowdingAr2 from "../../../img/cases-ar/crowding-2.png";
import crowdingAr3 from "../../../img/cases-ar/crowding-3.png";
import protrusionAr1 from "../../../img/cases-ar/protrusion-1.png";
import protrusionAr2 from "../../../img/cases-ar/protrusion-2.png";
import protrusionAr3 from "../../../img/cases-ar/protrusion-3.png";
import redo from "../../../img/redo.png";
import toothDesc from "../../../img/tooth-desc.png";
import squareTooth from "../../../img/square-tooth.png";
import circleTooth from "../../../img/circle-tooth.png";
import lineTooth from "../../../img/line-tooth.png";
import ReactHtmlParser from "react-html-parser";
import TreatmentPDFAr from "./TreatmentPDFAr";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WarningRegenerateDispatches from "../../../Payments/warning-regenerate-dispatches.component";

function SmilePlan() {
  const pdfRef = createRef();
  const pdfRef2 = createRef();
  const { id, plan_id } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let linkState = { planType: "", clinicStatus: "", clinicName: "", status: "", userId: id, pageTitle: "", treatmentStage: "" };
  if (location.state !== undefined) {
    const { planType, clinicStatus, clinicName, status, userId, pageTitle, treatmentStage } = location.state;
    linkState = { planType, clinicStatus, clinicName, status, userId, pageTitle, treatmentStage }
  }
  const { planType, clinicStatus, clinicName, status, userId, pageTitle } = linkState;

  const createMode = window.location.pathname.includes('create');
  const typeTp = !window.location.pathname.includes('refinement');
  const [fullName, setFullName] = useState("")
  const [isOutsourced, setIsOutsourced] = useState(false);
  const [city, setCity] = useState("")
  const [country, setCountry] = useState('')
  const [yourSmilePlanData, setYourSmilePlanData] = useState([]);
  const [displayable3dFiles, setDisplayable3dFiles] = useState({});
  const [numberOf3dFiles, setNumberOf3dFiles] = useState(0);
  const [IPRLink, setIPRLink] = useState("");
  const [TPLink, setTPLink] = useState("");
  const [valueNumberAlignersUpper, setValueNumberAlignersUpper] = useState("");
  const [valueNumberAlignersLower, setValueNumberAlignersLower] = useState("");
  const [labApproved, setLabApproved] = useState("");
  const [labApprovedDate, setLabApprovedDate] = useState("");
  const [doctorApproved, setDoctorApproved] = useState("");
  const [doctorApprovedDate, setDoctorApprovedDate] = useState("");
  const [popupActive, setPopupActive] = useState(false);
  const [disableButtonApproval, setDisableApprovalButtons] = useState(false);
  const [proceduresIprSheet, setProceduresIprSheet] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("1");
  const [arrayOfElements, setArrayOfElements] = useState([]);
  const [checkedIPR, setCheckedIPR] = useState(false);
  const [checkedIPRCompleted, setCheckedIPRCompleted] = useState(false);
  const [IPRFileKey, setIPRFileKey] = useState("");
  const [iPRFileName, setIPRFileName] = useState("");
  const [tpPlanName, setTpPlanName] = useState("");
  const [createdAt, setCreatedAt] = useState(moment(new Date()).format('LL'));
  const [tpType, setTptype] = useState(typeTp ? 'tp' : 'rtp');
  const [oem3DLink, setOem3DLink] = useState("");
  const [vimeo1Value, setVimeo1Value] = useState("");
  const [vimeo2Value, setVimeo2Value] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [optionsType, setOptionsType] = useState([
    { label: "Active", value: "Active" },
    { label: "Approved", value: "Approved" },
    { label: "Draft", value: "Draft" },
    { label: "Pending", value: "Pending" },
  ]);
  const [valuePublishDraft, setPublishDraft] = useState([{
    value: '',
    label: ''
  }])
  const [vimeoRefinementValue, setVimeoRefinementValue] = useState("");
  const [vimeo1Error, setVimeo1Error] = useState();
  const [vimeo2Error, setVimeo2Error] = useState();
  const [vimeoRefinementError, setVimeoRefinementError] = useState();
  const [valueLengthOfTreatment, setValueLengthOfTreatment] = useState("");
  const [valuePlanType, setValuePlanType] = useState([]);
  const [operationsValuePlanType, setOperationsValuePlanType] = useState([]);
  const [selectedAlignerWearTime, setSelectedAlignerWearTime] = useState("2-week");
  const [iprFileRequired, setIPRFileRequired] = useState(<InlineError message="" fieldID="myFieldID" />);
  const [invalidPlans, setInvalidPlans] = useState(<InlineError message="" fieldID="myFieldID" />);
  const [upperStepsError, setUpperStepsError] = useState("");
  const [lowerStepsError, setLowerStepsError] = useState("");
  const [valueTreatmentDescriptionEn, setvalueTreatmentDescriptionEn] = useState(RichTextEditor.createEmptyValue());
  const [valueTreatmentDescriptionAr, setvalueTreatmentDescriptionAr] = useState(RichTextEditor.createEmptyValue());
  const [checkedIsTeethImageVisible, setCheckedIsTeethImageVisible] = useState(false);
  const [selectResultData, setSelectResultData] = useState([])
  const [superImposedKey1, setSuperImposedKey1] = useState("");
  const [superImposedKey2, setSuperImposedKey2] = useState("");
  const [superImposedImagePreview1, setSuperImposedImagePreview1] = useState("");
  const [superImposedImagePreview2, setSuperImposedImagePreview2] = useState("");
  const [beforeStepValue, setBeforeStepValue] = useState("");
  const [beforeStepValueArray, setBeforeStepValuerray] = useState([]);
  const [checkedWebhook, setCheckedWebhook] = useState(false);
  const [checkedUpdateCustomerStage, setcheckedUpdateCustomerStage] = useState(false);
  const [iprUrl, setIprUrl] = useState("");
  const [response, setResponse] = useState("");
  const [handleLearnMore, setHandleLearnMore] = useState(false);
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [disableCheckedEmail, setDisableCheckedEmail] = useState(false);
  const [parentId, setParentId] = useState(0);
  const [level, setLevel] = useState(0);
  const [smilePlan, setSmilePlan] = useState(null);
  const [calendarId, setCalendarId] = useState('');
  const [treatmentStage, setTreatmentStage] = useState('');
  const [prevTpSteps, setPrevTpSteps] = useState([]);
  const [lastStepBeforeMcaRequired, setLastStepBeforeMcaRequired] = useState(<InlineError message="" fieldID="myFieldID" />);
  const [selectedTPDesigner, setSelectedTPDesigner] = useState("No One");
  const [oldDesignerName, setOldDesignerName] = useState('');
  const [selectedOutsourcedDesigner, setSelectedOutsourcedDesigner] = useState(query?.get('outsourced_designer') ?? "");
  const [outsourcedSuportDesigners, setOutsourcedSuportDesigners] = useState([]);
  const [selectedOutsourcedSuportDesigner, setSelectedOutsourcedSuportDesigner] = useState('');
  const [openActionModal, setOpenActionModal] = useState(false);
  const [autoSave, setAutoSave] = useState(0);
  const [pdfDetails, setPdfDetails] = useState({
    loading: false,
    isOpen: 0,
    generatePDFClicked: false,
  });
  const [optionsTPDesigner, setOptionsTPDesigner] = useState([
    { label: "Ghady Freiha", value: "Ghady Freiha" },
    { label: "Karine Shhaitly", value: "Karine Shhaitly" },
    { label: "Tania Abi Farraj", value: "Tania Abi Farraj" },
    { value: 'Jana Kabalan', label: 'Jana Kabalan' },
    { value: 'Ghadir Ismail', label: 'Ghadir Ismail' },
    { value: 'Zeina Rafeh', label: 'Zeina Rafeh' },
    { value: 'Kareem Mazloum', label: 'Kareem Mazloum' },
    { value: 'Elissa Mokh', label: 'Elissa Mokh' },
    { value: 'Jana Al Houssaini', label: 'Jana Al Houssaini' },
    { value: 'Hasan Daher', label: 'Hasan Daher' },
    { value: 'George Hakim', label: 'George Hakim' },
    { label: "No One", value: "No One" },
  ]);
  const optionsoutsourcedDesigner = [
    { label: "None", value: "" },
    { label: "KLine", value: "kline" },
    { label: "Joseph Sawaya (Freelance)", value: "Joseph Sawaya (Freelance)" },
  ];
  const optionsProceduresHashMap = { 
    redoScan: "Redo Scan",
    ipr: "IPR",
    attachments: "Attachments",
    // attachmentsRemoval: "Attachments Removal",
    // rebondAttachment: "Rebond Attachment",
    buttons: "Buttons", // elastics
    // buttonsRemoval: "Buttons Removal",
  };
  const [checkedRequireRedo, setCheckedRequireRedo] = useState(false);
  const [checkedRequireOpg, setCheckedRequireOpg] = useState(false);
  const [selectedRequiredRedo, setSelectedRequiredRedo] = useState("");
  const [checkedNightTimeAligner, setCheckedNightTimeAligner] = useState(false);
  const [firstAppointmentNumber, setFirstAppointmentNumber] = useState(1);
  const [checkedPreferred, setCheckedPreffered] = useState(false);
  const [checkedHidePrice, setcheckedHidePrice] = useState(false);
  const [checkedHideBundles, setCheckedHideBundles] = useState(false);
  const [childItems, setChildItems] = useState([]);
  const [bypassEarlyBird, setBypassEarlyBird] = useState(false);
  const [shareable3DLink, setShareable3DLink] = useState("");
  const [loading3D, setLoading3D] = useState(false);
  const tpOptions = [
    { label: "-- Select type for this plan --", value: "" },
    { label: "Refinement plan", value: "rtp" },
    { label: "MCA", value: "mca" },
  ];
  const proceduresHashMap =
  {
    attachments: "Attachments",
    attachmentsRemoval: "Attachments Removal",
    buttons: "Buttons",
    buttonsRemoval: "Buttons Removal",
    cleaning: "Cleaning",
    dental_checkup: "Dental Checkup",
    ipr: "IPR",
    mcaScan: "MCA - Scan",
    mcaScanError: "MCA Scan - Scan Error",
    replace_powerchain: "Replace Powerchain",
    opg: "OPG",
    periodontal_checkup: "Periodontal Checkup",
    rebondAttachment: "Rebond Attachment",
    RedoIk: "Redo Ik",
    redoScan: "Redo Scan - Scan Error",
    redoScanErrorDental: "Redo Scan - Post Dental Work",
    redoScanCleaning: "Redo Scan - Post Cleaning",
    redoScanWire: "Redo Scan - Post Wire Removal",
    redoScanPhotos: "Redo Scan Photos",
    refinementScan: "Refinement Scan",
    refinementScanError: "Refinement Scan - Scan Error",
    removeFixedRetainers: "Remove Fixed Retainers",
    retainer_scan: "Retainer Scan",
    post_treatment_photos: "Post Treatment Photos",
    xray: "X-ray",
    extraction: "Extraction",
    measureSpaces: "Measure Spaces",
    rebondButtons: "Rebond Buttons",
    removeExcessAroundAttachments: "Remove Excess Around Attachments",
    inClinicWhitening: "In-Clinic Whitening",
    mca_photos: "MCA Photos",
    refinement_photos: "Refinement Photos",
  };
  const [comments, setComments] = useState({
    dentalWork: '',
    extraction: ''
  });
  const [selectedCaseTypes, setSelectedCaseTypes] = useState([]);
  const [oemOptions, setOemOptions] = useState([]);
  const [oemCompany, setOemCompany] = useState([{label: 'None', value: null}]);
  const [stlLink, setStlLink] = useState("");
  const [planSelected, setPlanSelected] = useState(null);

  const procDescriptions = [
    {
      id: 0,
      icon: redo,
      value: 'redoScan',
      label: 'Redo Scan',
      labelAr: <p className="tp-pdf-single-procedure-title">إعادة&nbsp;إجراء&nbsp;مسح&nbsp;للأسنان</p>,
      description: 'Sometimes the scans we receive of your teeth are enough to create a treatment plan, but not accurate enough to print your custom aligners. To ensure your aligners fit accurately, we will need you to visit the clinic for a Redo Scan to help us create the best outcome for your smile.',
      descriptionAr: <p className="tp-pdf-single-procedure-desc">أحياناً<span>،</span> عندما نحصل على صور مسح أسنانك تكون كافية لتصميم خطة علاجك<span>،</span> ولكنها ليست دقيقة بما يكفي للتمكّن من طباعة تقويم الأسنان<span>.</span> بهدف التأكد من أن التقويم ملائم تماماً لأسنانك<span>،</span> سنطلب منك إعادة إجراء مسح جديد لأسنانك في العيادة<span>،</span> ما يساعدنا على ضمان حصولك على أفضل النتائج</p>,
      img: null,
    },
    {
      id: 1,
      icon: toothDesc,
      value: 'ipr',
      label: 'IPR',
      labelAr: <p className="tp-pdf-single-procedure-title">إجراء&nbsp;فرغات&nbsp;بين&nbsp;الأسنان</p>,
      description: 'IPR, or Interproximal Reduction, is a gentle technique used to create space between your teeth. It\'s painless and completely safe.This procedure is applied to ensure your teeth have the ideal amount of room for alignment while they move into their desired positions smoothly. Spaces created with IPR will be closed by the end of your treatment. Your IPR Sheet indicates where and when these spaces will be created during your treatment.',
      descriptionAr: <p className="tp-pdf-single-procedure-desc">يعتمد هذا الإجراء البسيط على خلق فراغات بين أسنانك<span>،</span> ويعدّ إجراء آمناً وخالياً من الألم<span>.</span> وتساعد هذه الطريقة على توفير المساحة الكافية لتحرّك أسنانك براحة أكبر خلال العلاج<span>.</span> كما أن هذه الفراغات ستختفي عند انتهاء علاجك<span>.</span> يمكنك التحقق من خطة علاجك لمعرفة موضع هذه الفراغات بين الأسنان وموعد إجرائها</p>,
      img: null,
    },
    {
      id: 2,
      icon: squareTooth,
      value: 'attachments',
      label: 'Attachments',
      labelAr: <p className="tp-pdf-single-procedure-title">المرفقات</p>,
      description: 'Attachments are small, tooth-colored composite shapes placed strategically on your teeth to facilitate precise movement during your treatment. They are designed to interact with the aligners, aiding in the gradual shift of your teeth. Don\'t worry, they\'re virtually invisible and crafted to blend seamlessly with your smile and will be removed at the end of your treatment.',
      descriptionAr: <p className="tp-pdf-single-procedure-desc">وهي عبارة عن قطع صغيرة بلون الأسنان تثبّت بشكل استراتيجي على الأسنان لتحديد حركة الأسنان خلال العلاج<span>.</span> وهي مصممة لتتفاعل مع التقويم<span>،</span> وتساعد في عملية تحريك الأسنان<span>.</span> لا داعي للقلق<span>،</span> هذه الحاصرات تكون غير مرئية ومصممة خصيصاً لكي لا تظهر عندما تبتسم وتتمّ إزالتها في نهاية علاجك</p>,
      img: TPR1,
      imgAr: TPR4,
    },
    {
      id: 3,
      icon: circleTooth,
      value: 'buttons',
      label: 'Buttons',
      labelAr: <p className="tp-pdf-single-procedure-title">الأزرار</p>,
      description: 'Buttons are like gentle nudges for your aligners, helping guide your teeth into alignment with added precision. These unobtrusive, temporary pieces are customized to your unique case and expertly placed on your teeth to enhance the effectiveness of your aligners. They must be used with elastics.',
      descriptionAr: <p className="tp-pdf-single-procedure-desc">تساعد هذه الأزرار أسنانك على التحرّك بالطريقة الصحيحة من خلال الضغط عليها بشكل لطيف<span>.</span> تُصمم هذه الأزرار الموقتة وغير المزعجة وفق حالتك<span>،</span> ويحرص الخبراء على تثبيتها على أسنانك لزيادة فعالية التقويم ويجب استخدامها مع الأشرطة المطاطية</p>,
      img: TPR2,
    },
    {
      id: 4,
      icon: lineTooth,
      value: 'elastics',
      label: 'Elastics',
      labelAr: <p className="tp-pdf-single-procedure-title">الأربطة&nbsp;المطاطية</p>,
      description: 'Elastics work hand-in-hand with the buttons and are a versatile and effective component of your treatment. They\'re small, stretchy bands that create targeted forces to enhance the alignment of your teeth. Don\'t worry, they\'re easy to use. Wearing and replacing them as directed will lead to noticeable improvement. Think of them as little helpers, working behind the scenes to bring out the best in your smile.',
      descriptionAr: <p className="tp-pdf-single-procedure-desc">تترافق هذه الأربطة المطاطية مع الأزرار<span>،</span> وهي ضرورية وفعالة لعلاجك<span>.</span> وهي عبارة عن أربطة مطاطية صغيرة تحدث ضغطا مركّزا لتعزيز فرصة حصولك على أسنان مستقيمة<span>.</span> لا داعي للقلق<span>،</span> استخدام هذه الأربطة سهل جداً<span>،</span> واتباع التوجيهات في وضعها وإزالتها يساعدك في الحصول على نتيجة أفضل<span>.</span> يمكن اعتبارها عاملا مساعدا بسيطا يساهم في حصولك على ابتسامة مثالية</p>,
      img: TPR3,
    },
  ];

  const [procedures, setProcedures] = useState(procDescriptions);

  const cases = [
    {
      label: 'SPACING',
      labelAr: <p className="tp-pdf-case-p">تباعد&nbsp;أسنان</p>,
      images: [
        {
          img: spacingEn1,
          imgAr: spacingAr1,
          titleEn: '8 months - 16 steps',
          descriptionEn: 'IPR done, attachments used',
          titleAr: <p className="tp-pdf-case-img-p">8&nbsp;أشهر - &nbsp;16&nbsp;خطوة</p>,
          descriptionAr: <p className="tp-pdf-case-img-p">بعد&nbsp;إجراء&nbsp;فروقات&nbsp;بين&nbsp;الأسنان</p>,
        },
        {
          img: spacingEn2,
          imgAr: spacingAr2,
          titleEn: '8 months - 16 steps',
          descriptionEn: '',
          titleAr: <p className="tp-pdf-case-img-p">8&nbsp;أشهر - &nbsp;16&nbsp;خطوة</p>,
          descriptionAr: null,
        },
        {
          img: spacingEn3,
          imgAr: spacingAr3,
          titleEn: '8 months - 16 steps',
          descriptionEn: 'IPR done, attachments used',
          titleAr: <p className="tp-pdf-case-img-p">8&nbsp;أشهر - &nbsp;16&nbsp;خطوة</p>,
          descriptionAr: <p className="tp-pdf-case-img-p">بعد&nbsp;إجراء&nbsp;فروقات&nbsp;بين&nbsp;الأسنان</p>,
        }
      ]
    },
    {
      label: 'CROWDING',
      labelAr: <p className="tp-pdf-case-p">تزاحم&nbsp;أسنان</p>,
      images: [
        {
          img: crowdingEn1,
          imgAr: crowdingAr1,
          titleEn: '8 months - 16 steps',
          descriptionEn: 'IPR done, attachments used',
          titleAr: <p className="tp-pdf-case-img-p">8&nbsp;أشهر - &nbsp;16&nbsp;خطوة</p>,
          descriptionAr: <p className="tp-pdf-case-img-p">بعد&nbsp;إجراء&nbsp;فروقات&nbsp;بين&nbsp;الأسنان</p>,
        },
        {
          img: crowdingEn3,
          imgAr: crowdingAr3,
          titleEn: '8 months - 16 steps',
          descriptionEn: 'IPR done, attachments used',
          titleAr: <p className="tp-pdf-case-img-p">8&nbsp;أشهر - &nbsp;16&nbsp;خطوة</p>,
          descriptionAr: <p className="tp-pdf-case-img-p">بعد&nbsp;إجراء&nbsp;فروقات&nbsp;بين&nbsp;الأسنان</p>,
        },
        {
          img: crowdingEn2,
          imgAr: crowdingAr2,
          titleEn: '8 months - 16 steps',
          descriptionEn: 'IPR done, attachments used',
          titleAr: <p className="tp-pdf-case-img-p">8&nbsp;أشهر - &nbsp;16&nbsp;خطوة</p>,
          descriptionAr: <p className="tp-pdf-case-img-p">بعد&nbsp;إجراء&nbsp;فروقات&nbsp;بين&nbsp;الأسنان</p>,
        },
      ]
    },
    {
      label: 'PROTRUSION',
      labelAr: <p className="tp-pdf-case-p">بروز&nbsp;أسنان</p>,
      images: [
        {
          img: protrusionEn1,
          imgAr: protrusionAr1,
          titleEn: '8 months - 16 steps',
          descriptionEn: 'IPR done, attachments used',
          titleAr: <p className="tp-pdf-case-img-p">8&nbsp;أشهر - &nbsp;16&nbsp;خطوة</p>,
          descriptionAr: <p className="tp-pdf-case-img-p">بعد&nbsp;إجراء&nbsp;فروقات&nbsp;بين&nbsp;الأسنان</p>,
        },
        {
          img: protrusionEn2,
          imgAr: protrusionAr2,
          titleEn: '8 months - 16 steps',
          descriptionEn: 'IPR done, attachments used',
          titleAr: <p className="tp-pdf-case-img-p">8&nbsp;أشهر - &nbsp;16&nbsp;خطوة</p>,
          descriptionAr: <p className="tp-pdf-case-img-p">بعد&nbsp;إجراء&nbsp;فروقات&nbsp;بين&nbsp;الأسنان</p>,
        },
        {
          img: protrusionEn3,
          imgAr: protrusionAr3,
          titleEn: '13 months - 26 steps',
          descriptionEn: 'IPR done, attachments used',
          titleAr: <p className="tp-pdf-case-img-p">13&nbsp;أشهر - &nbsp;26&nbsp;خطوة</p>,
          descriptionAr: <p className="tp-pdf-case-img-p">بعد&nbsp;إجراء&nbsp;فروقات&nbsp;بين&nbsp;الأسنان</p>,
        }
      ]
    },
  ];

  const handleProductionCompany = useCallback((value) => setOemCompany(value))
  const handleSelectChange = async (selectedOption) => {
    setSelectedCaseTypes(selectedOption);
  };
  const handleChangeUpdateCustomerStage = useCallback(
    (newChecked) => setcheckedUpdateCustomerStage(newChecked),
    []
  );
  const handleSelectChangeOption = useCallback(
    (selectedOption) => setSelectedOption(selectedOption),
    []
  );
  const handleChangeUpperAlignersNb = useCallback((newValue) => {
    setValueNumberAlignersUpper(newValue);
    setUpperStepsError("");
  }, []);
  const handlePublishDraft = useCallback((value) => setPublishDraft(value))
  const handleChangeLowerAlignersNb = useCallback((newValue) => {
    setValueNumberAlignersLower(newValue);
    setLowerStepsError("");
  }, []);
  const handleChangeVimeo1Value = (newValue) => {
    setVimeo1Value(newValue);
    setVimeo1Error("");
  };
  const handleChangeVimeo2Value = (newValue) => {
    setVimeo2Value(newValue);
    setVimeo2Error("");
  };
  const handleChangeRefinementValue = (newValue) => {
    setVimeoRefinementValue(newValue);
    setVimeoRefinementError("");
  };
  const handleChangeVimeo1Error = useCallback(
    (newValue) => setVimeo1Error(newValue),
    []
  );
  const handleChangeVimeo2Error = useCallback(
    (newValue) => setVimeo2Error(newValue),
    []
  );
  const handleChangeRefinementError = useCallback(
    (newValue) => setVimeoRefinementError(newValue),
    []
  );
  const handleChangeUpperStepsError = useCallback(
    (newValue) => setUpperStepsError(newValue),
    []
  );
  const handleChangeLowerStepsError = useCallback(
    (newValue) => setLowerStepsError(newValue),
    []
  );
  const handleTreatmentDescriptionEn = (newValue) => {
    setvalueTreatmentDescriptionEn(newValue);
  };

  const handleSelectTpType = (value) => {
    setTptype(value);
    if(value == 'mca'){
      handleStepPrevTpStops(smilePlan ? smilePlan.parent_id : null);
    }
  }

  function handleCalendarStepChange(val) {
    setLastStepBeforeMcaRequired(<InlineError message="" fieldID="myFieldID" />);
    setCalendarId(val);
  }
  const handleTreatmentDescriptionAr = (newValue) => {
    setvalueTreatmentDescriptionAr(newValue);
  };
  const handleCheckedIsTeethImageVisible = (newValue) => {
    setCheckedIsTeethImageVisible(newValue);
  };
  const handleChangeArrayElements = (newValue) => {
    setArrayOfElements(newValue);
  };
  const handeChangeCheckedIPR = (newValue) => {
    setCheckedIPR(newValue);
  };
  const handleChangeIPRCompleted = (newValue) => {
    setCheckedIPRCompleted(newValue);
  };
  const handeChangeTreatmentLength = (newValue) => {
    setValueLengthOfTreatment(newValue);
  };
  const handeChangeSelectedWearTime = (newValue) => {
    setSelectedAlignerWearTime(newValue);
  };
  const handleChangeEmail = useCallback(
    (newChecked) => setCheckedEmail(newChecked),
    []
  );
  const handleChangeSuperImposedKey1 = useCallback(
    (value) => setSuperImposedKey1(value),
    []
  );
  const handleChangeSuperImposedKey2 = useCallback(
    (value) => setSuperImposedKey2(value),
    []
  );

  const handleChangeBeforeStepNumber = useCallback(
    (value) => setBeforeStepValue(value),
    []
  );
  const handleChangeBeforeStepNumberArray = useCallback(
    (value) => setBeforeStepValuerray(value),
    []
  );
  const handeChangeIPRkeyFile = (newValue) => {
    setIPRFileKey(newValue);
  };
  const handeChangeIPRFileName = (newValue) => {
    setIPRFileName(newValue);
  };
  const handleChangeWebhook = useCallback(
    (newChecked) => setCheckedWebhook(newChecked),
    []
  );

  const handleSelectChangeTPDesigner = (value) => {
    setSelectedTPDesigner(value);
    save();
  };
  const handleSelectChangeOutSourcedDesigner = (value) => {
    setSelectedOutsourcedDesigner(value);
    if(!value) {
      setSelectedOutsourcedSuportDesigner('');
    }
    save();
  };
  const handleChangeOutsourcedSupportDesigner = (value) => {
    setSelectedOutsourcedSuportDesigner(value);
    save();
  };
  const handleValuePlanType = useCallback((value) => setValuePlanType(value))
  const handleOperationsValuePlanType = useCallback((value) => setOperationsValuePlanType(value))

  const handleChangeRequireRedo = useCallback(
    (newChecked) => setCheckedRequireRedo(newChecked),
    []
  );
  const handleChangeRequireOpg = useCallback(
    (newChecked) => setCheckedRequireOpg(newChecked),
    []
  );
  const handleSelectChangeRequiredRedoSelection = useCallback(
    (value) => setSelectedRequiredRedo(value),
    []
  );

  const handleChangeCheckedNightTimeAligner = useCallback(
    (newChecked) => setCheckedNightTimeAligner(newChecked),
    []
  );
  const handleChangeBypassEarlyBird = useCallback(
    (newChecked) => setBypassEarlyBird(newChecked),
    []
  );
  const handleChangeCheckedPreferred = useCallback(
    (newChecked) => setCheckedPreffered(newChecked),
    []
  );
  const handleHidePrice = useCallback(
    (newChecked) => setcheckedHidePrice(newChecked),
    []
  );
  
  const handleChangeHideBundles = useCallback(
    (newChecked) => setCheckedHideBundles(newChecked),
    []
  );

  const sendImageGuide = (filesGuide) => {
    const NewDate = moment(new Date(), "DD-MM-YYYY");
    let imageType = filesGuide.type;
    let headersVar = null;
    let imageKeyGuide = null;
    let url2 = "";
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    form_data.append("file_name", `Basma_TreatmentPlan_${fullName ? fullName.replace(' ', '_') : ''}_${formatDate(NewDate)}.pdf`);

    axios
      .post("/admin/v1/images/s3", form_data, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        imageKeyGuide = res.data.key;
        url2 = res.data.url;
        headersVar = res.data.headers;
        axios.put(url2, filesGuide, {
          headers: {
            "x-amz-acl": "public-read-write",
            "Content-Type": imageType,
          },
        }).then((res) => {
          if(pdfDetails.isOpen == 1) {
            setPdfDetails({
              loading: false,
              isOpen: 0,
              generatePDFClicked: false,
            });
          }
          handleSave(false, imageKeyGuide);
        }).catch((err) => {
          console.log(err);
        });
      }).catch((err) => {
        console.log(err);
      });
  }

  function formatDate(date) {
    if (date !== undefined && date !== "") {
      var myDate = new Date(date);
      var month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ][myDate.getMonth()];
      var str = myDate.getDate() + " " + month + " " + myDate.getFullYear();
      return str;
    }
    return "";
  }

  function handleStepPrevTpStops(parentId = null) {
    let parent = parentId ? parentId : plan_id;
    axios.get(`admin/v1/users/${id}/get-prev-tp-steps/${parent}`, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }).then((res) => {
      setPrevTpSteps(res.data.data);
    }).catch((err) => console.log(err));
  }
  const generateShareableLink = async () => {
    try {
      setLoading3D(true);
      const response = await axios.get(`admin/v1/your_smile_plan/${plan_id}/share-link`, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
      );
      if (response.status === 200) {
        setShareable3DLink(response.data.data);
      }
    } catch (error) {
      console.log("Error in generateShareableLink",error);
    } finally {
      setLoading3D(false);
    }
  }

  const handleActiveSelect = (disable) => {
    const optionTypeArray = optionsType.map((res) => {
      if (res.value == 'Active') {
        return {
          ...res,
          disabled: disable,
        }
      }
      return res;
    });
    setOptionsType(optionTypeArray);
  }

  const approvedFunc = async (value) => {
    setLoading(true)
    try {
      let config = {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }
      const body = {
        [value]: value,
        plan_id: plan_id,
        country: country,
      };
      const response = await axios.post(`admin/v1/smile_plan/admin/approved`, body, config);
      handleActiveSelect(response?.data?.disabled);
      if (response?.data?.success) {
        if (response.data.lab_approved_name != '' && response.data.lab_approved_name != null) {
          setLabApproved(response.data.lab_approved_name);
          setLabApprovedDate(response.data.lab_approved_date);
        }
        if (response.data.doctor_approved_name != '' && response.data.doctor_approved_name != null) {
          setDoctorApproved(response.data.doctor_approved_name);
          setDoctorApprovedDate(response.data.doctor_approved_date);
        }
        if (response?.data?.status == "Active") {
          setPublishDraft([{ value: "Active", label: "Active" }]);
        } else if (!response?.data?.disabled) {
          setPublishDraft([{ value: "Approved", label: "Approved" }]);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const addTp = () => {
    history.push(`/admin/users/${id}/dashboard/yoursmileplan/create`, linkState);
    window.location.reload();
  }
  
  const handleSelectRouting = (itemId) => {
    history.push(`/admin/users/${id}/dashboard/yoursmileplan/${itemId}/refinement/edit`)
    window.location.reload();
  }

  const handleAddRtp = () => {
    history.push(`/admin/users/${id}/dashboard/yoursmileplan/${plan_id}/refinement/create`)
    window.location.reload();
  }

  const selectTp = (e) => {
    let tpId = e.target.value;
    history.push(`/admin/users/${id}/dashboard/yoursmileplan/${tpId}/update`);
    window.location.reload();
  }

  const fetchData = (load, oemCompanyOptions = []) => {
    axios.get(`admin/v1/tp-outsourced-support-designers`, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }).then((result) => {
      let supportDesignerOtions = result.data.designers.map(designer => ({
        label: designer.full_name,
        value: designer.id,
      }));
      supportDesignerOtions.unshift({ label: 'None', value: '' });
      setOutsourcedSuportDesigners(supportDesignerOtions);
    }).catch((err) => console.log(err));
    if (plan_id) {
      if(!createMode) {
        if(load) {
          setLoading(true);
        }
        let config = {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
        axios.get(`admin/v1/users/${id}/smile-plan/${plan_id}?include=refinement`, config).then((result) => {
          setSmilePlan(result.data.data);
          if(!typeTp) {
            setLevel(result.data.data.level);
            setParentId(result.data.data.parent_id);
            setTreatmentStage(result?.data?.data?.name);
            let tp_type = tpOptions.find(tp => tp.value == result?.data?.data.type)?.value;
            if(tp_type) {
              setTptype(tp_type);
            }
            if(tp_type == 'mca'){
              setCalendarId(result?.data?.data.parent_tp_interrupted_step);
              handleStepPrevTpStops(result.data.data.parent_id);
            }
          }
          let caseTypes = [];
          if(result.data.data.protrusion) {
            caseTypes.push({value: 'Protrusion', label: 'Protrusion'});
          }
          if(result.data.data.crowding) {
            caseTypes.push({value: 'Crowding', label: 'Crowding'});
          }
          if(result.data.data.spacing) {
            caseTypes.push({value: 'Spacing', label: 'Spacing'});
          }
          setSelectedCaseTypes(caseTypes);
          setTpPlanName(result.data.data.tp_name);
          if(result.data.data.created_at) {
            setCreatedAt(moment(result.data.data.created_at).format('LL'));
          }
          setCheckedPreffered(result?.data?.data.preferred ? true : false);
          setCheckedNightTimeAligner(
            result?.data?.data?.night_time_aligner ? true : false
          );
          const filteredProceduresIprSheet = [];
          if (result?.data?.data?.ipr_url) {
            filteredProceduresIprSheet.push({ previous: true, url: result.data.data.ipr_url });
          }
          if (result?.data?.procedures) {
            filteredProceduresIprSheet.push(...result?.data?.procedures);
          }
          setProceduresIprSheet(filteredProceduresIprSheet);
          result?.data?.data && setResponse(result.data.data);
          result?.data?.data?.designer_3d_file && setOem3DLink(result.data.data.designer_3d_file);
          result?.data?.data?.video_id && setVimeo1Value(result.data.data.video_id);
          result?.data?.data?.video_id_2 && setVimeo2Value(result.data.data.video_id_2);
          result.data.data.designer_3d_file ?  setSelectedOption('3') : (result.data.data.priority_for_3d ? setSelectedOption('1') : setSelectedOption('2'));
          setYourSmilePlanData(result.data.data);
          setShareable3DLink(result?.data?.data?.shareable3dLink);
          setDisplayable3dFiles(result.data.displayable_3d_files);
          result?.data?.data?.number_of_aligner_lower !== null && setValueNumberAlignersLower(
            String(result.data.data.number_of_aligner_lower)
          );
          result?.data?.data?.number_of_aligner_upper !== null && setValueNumberAlignersUpper(
            String(result.data.data.number_of_aligner_upper)
          );
          result?.data?.data?.description?.en && setvalueTreatmentDescriptionEn(
            RichTextEditor.createValueFromString(
              result.data.data.description.en,
              "html"
            )
          );
          result?.data?.data?.description?.ar && setvalueTreatmentDescriptionAr(
            RichTextEditor.createValueFromString(
              result.data.data.description.ar,
              "html"
            )
          );
          result?.data?.data?.treatment_length && setValueLengthOfTreatment(String(result.data.data.treatment_length));
          result.data?.data?.cycle_period && setSelectedAlignerWearTime(
            getWearTime(result.data.data.cycle_period)
          );
          setNumberOf3dFiles(result.data.data.three_dimension_smile_url.length);
          setPopupActive(false);
          setCheckedIPR(result.data.data.ipr_is_active);
          setCheckedIPRCompleted(result.data.data.ipr_is_complete);
          result.data.data.ipr_url && setIPRLink(result.data.data.ipr_url);
          result.data.data.guide_url && setTPLink(result.data.data.guide_url);
          result?.data?.data?.is_teeth_visible && setCheckedIsTeethImageVisible(result.data.data.is_teeth_visible);
          result?.data?.data?.refinement_video_id && setVimeoRefinementValue(result?.data?.data?.refinement_video_id);
          result?.data?.data?.superimposed_one_url && setSuperImposedImagePreview1(result.data.data.superimposed_one_url);
          result?.data?.data?.superimposed_two_url && setSuperImposedImagePreview2(result.data.data.superimposed_two_url);
          result?.data?.data?.ipr_before_step_number && setBeforeStepValue(String(result.data.data.ipr_before_step_number));
          result?.data?.data?.ipr_url && setIprUrl(result.data.data.ipr_url);
          result?.data?.data?.designer_name && setSelectedTPDesigner(String(result.data.data.designer_name));
          result?.data?.data?.old_designer_name && setOldDesignerName(`Transferred from ${result.data.data.old_designer_name}`);
          result?.data?.data?.outsourced_designer && setSelectedOutsourcedDesigner(String(result.data.data.outsourced_designer));
          result?.data?.data?.outsourced_support_designer_id && setSelectedOutsourcedSuportDesigner(result.data.data.outsourced_support_designer_id);
          result?.data?.data?.ipr ? setIPRFileName(result.data.data.ipr.substring(result.data.data.ipr.indexOf("_") + 1)) : setIPRFileName('');
          setCheckedRequireRedo(result.data.data.requires_redo);
          setCheckedRequireOpg(result.data.data.requires_opg);
          result?.data?.data?.redo_type && setSelectedRequiredRedo(result.data.data.redo_type);
          setChildItems(result?.data?.data?.child_items);
          result?.data?.data?.plan_type && setValuePlanType([{ label: result.data.data.plan_type, value: result.data.data.plan_type }]);
          result?.data?.data?.operations_plan_type && setOperationsValuePlanType([{ label: result.data.data.operations_plan_type, value: result.data.data.operations_plan_type }]);
          result?.data?.data?.hide_price && setcheckedHidePrice(true);
          result?.data?.data?.hide_bundle && setCheckedHideBundles(true);
          result?.data?.data?.bypass_early_bird && setBypassEarlyBird(true);
          setPublishDraft([{
            value: result.data.data.status,
            label: result.data.data.status
          }]);
          handleActiveSelect(result.data.data.disabled);
          setLabApproved(result.data.data.lab_approved_name);
          setLabApprovedDate(result.data.data.lab_approved_date);
          setDoctorApproved(result.data.data.doctor_approved_name);
          setDoctorApprovedDate(result.data.data.doctor_approved_date);
          setCheckedEmail(result.data.data.user_email_sent == 1 ? true : false);
          setDisableCheckedEmail(result.data.data.user_email_sent == 1 ? true : false);
          setDisableApprovalButtons(result.data.data.status == optionsType[1].value ? true : false);
          setcheckedHidePrice(result.data.data.hide_price == 1 ? true : false);
          setCheckedHideBundles(result.data.data.hide_bundle);
          setBypassEarlyBird(result.data.data.bypass_early_bird == 1 ? true : false);
          setStlLink(result.data.data.stl_files_link);
          let companyOptions = [...oemCompanyOptions]
          if(companyOptions.length){
            let index  = companyOptions.findIndex((co)=>co.value==result.data.data.production_company_id)
            if(index>=0){
              setOemCompany([companyOptions[index]])
            }
          }
        }).catch((err) => {
            console.log('fetchData error', err);
        })
      }
    }
    setLoading(false);
  }

  function cyclePeriod(param) {
    switch (param) {
      case "1-week":
        return 7;
      case "2-week":
        return 14;
      case "10-day":
        return 10;
      default:
        return 0;
    }
  }

  function handleDelete() {
    axios.delete(`admin/v1/users/${id}/smile-plan/${plan_id}/delete`, {
      headers: {
        Authorization: "Bearer " + Cookie.get("token"),
      },
    }).then((res) => {
      console.log(res)
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      history.push("/admin/users/" + id + "/dashboard");
    });
  }

  const  pixelsToPoints = (pixels) => {
    return pixels * 0.75;
  }

  const generatePdf = async() => {
    setPdfDetails(prev => ({
      ...prev,
      loading: true,
      generatePDFClicked: true,
    }));

    let pdfRefEn = pdfRef.current;
    let pdfRefAr = pdfRef2.current;

    const height =  pixelsToPoints(pdfRefEn?.offsetHeight);
    const width = pixelsToPoints(900);
    const doc = new jsPDF({
      unit: "pt",
      format: [height, width]
    });

    // Capture the component as an image
    const canvas = await html2canvas(pdfRefEn);
    const imgData = canvas.toDataURL("image/jpeg", 1);
    // Add the image to the PDF
    doc.addImage(imgData, 'JPEG', 0, 0, width, height);

    const canvas2 = await html2canvas(pdfRefAr);
    let h2 = pdfRefAr?.offsetHeight;
    const height2 =  pixelsToPoints(h2);
    const imgData2 = canvas2.toDataURL("image/jpeg", 1);
    doc.addPage([width, height2]);
    doc.addImage(imgData2, 'JPEG', 0, 0, width, height2);

    // Upload generated PDF to S3
    const pdfBlob = doc.output('blob');
    sendImageGuide(pdfBlob);
  };

  function openPDF(opened = 1) {
    // opened = 1, after generating PDF agent will be redirected to dashboard
    // opened = 2, after generating PDF agent stays in place
    setPdfDetails(prev => ({
      ...prev,
      isOpen: opened,
    }));
  }

  const checkForDispatches = async (mode = 1) => {
    if (!valueNumberAlignersUpper || !valueNumberAlignersLower) {
      setUpperStepsError("This field is required");
      setLowerStepsError("This field is required");
      return;
    }
    try {
      if (plan_id != null) {
        const body = {
          numberOfUpperAligners: parseInt(valueNumberAlignersUpper),
          numberOfLowerAligners: parseInt(valueNumberAlignersLower)
        }
        setPopupActive(true);
        const response = await axios.post(
            `admin/v1/users/${id}/smile-plan/${plan_id}/checkDispatches`,
            body,
            {
              headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
              },
            }
        );
        if (response.status === 200) {
          const { success, message, error } = response.data;
          if (error) {
            toast.error(message, 3000);
            return;
          }
          if (!success) {
            setOpenWarning(true);
            setWarningMessage(message);
          } else {
            openPDF(mode);
          }
        }
      } else {
        openPDF(mode);
      }
    } catch (error) {
      console.error('Error in dispatch checkForDispatches api', error);
      setPopupActive(false);
    } finally {
      setPopupActive(false);
    }
  };
  
  const getUser = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`admin/v1/users/${id}/get-user`, {
        headers: {
          Authorization: `Bearer ${Cookie.get('token')}`,
        },
      });
      if (response.status === 200) {
        const {success} = response.data;
        if (success) {
          setFullName(response?.data?.user?.full_name);
        }
      }
    } catch (e) {
      console.log('Error in get User API', e);
    } finally {
      setLoading(false)
    }
  }

  function handleSave(autoSaved, imageKeyGuide) {
    let errorBoolean = false;
    let errorIndex = 0;
    let three_dimension_smile = [];
    for (var i = 0; i < [...arrayOfElements].length; i++) {
      if (
        [...arrayOfElements][i].imagekey.upperObj === "" ||
        [...arrayOfElements][i].imagekey.upperMtl === "" ||
        [...arrayOfElements][i].imagekey.lowerObj === "" ||
        [...arrayOfElements][i].imagekey.lowerMtl === ""
      ) {
        errorBoolean = true;
        errorIndex = i;
        break;
      }
    }

    if (
      !valueNumberAlignersUpper ||
      !valueNumberAlignersLower ||
      (checkedIPR && !IPRFileKey && !iprUrl)
    ) {
      checkedIPR &&
        !IPRFileKey &&
        !iprUrl &&
        setIPRFileRequired(
          <InlineError message="IPR file is required" fieldID="myFieldID" />
        );
      !valueNumberAlignersUpper && setUpperStepsError("This field is required");
      !valueNumberAlignersLower && setLowerStepsError("This field is required");
    } else {
      pdfDetails.isOpen !== 2 && setPopupActive(true);
      for (var i = 0; i < [...arrayOfElements].length; i++) {
        let object = {
          upper: { obj: "", mtl: "" },
          lower: { obj: "", mtl: "" },
        };
        object["upper"]["obj"] = [...arrayOfElements][i].imagekey.upperObj;
        object["upper"]["mtl"] = [...arrayOfElements][i].imagekey.upperMtl;
        object["lower"]["obj"] = [...arrayOfElements][i].imagekey.lowerObj;
        object["lower"]["mtl"] = [...arrayOfElements][i].imagekey.lowerMtl;
        three_dimension_smile.push(object);
      }
      let parent_id = parentId ? parentId : (createMode && plan_id ? plan_id : null);
      const bodyObj = {
        ...(three_dimension_smile.length && {
          three_dimension_smile: three_dimension_smile,
        }),
        ...(oem3DLink && {
          designer_3d_file: oem3DLink
        }),
        ...(vimeo1Value && {
          video_id: vimeo1Value,
        }),
        ...(vimeo2Value && {
          video_id_2: vimeo2Value,
        }),
        ...(imageKeyGuide && { guide: imageKeyGuide }),
        ...(parseInt(response.number_of_aligner_upper) !=
          parseInt(valueNumberAlignersUpper) && {
          number_of_aligner_upper: parseInt(valueNumberAlignersUpper),
        }),
        ...(parseInt(response.number_of_aligner_lower) !=
          parseInt(valueNumberAlignersLower) && {
          number_of_aligner_lower: parseInt(valueNumberAlignersLower),
        }),
        ...(!typeTp && valueNumberAlignersUpper && {
          number_of_refinement_aligner_upper: parseInt(
            valueNumberAlignersUpper
          ),
        }),
        ...(!typeTp && valueNumberAlignersLower && {
          number_of_refinement_aligner_lower: parseInt(
            valueNumberAlignersLower
          ),
        }),
        treatment_length: valueLengthOfTreatment,
        priority_for_3d: selectedOption != "1" ? false : true,
        cycle_period: cyclePeriod(selectedAlignerWearTime),
        description: {
          en: valueTreatmentDescriptionEn.toString("html"),
          ar: valueTreatmentDescriptionAr.toString("html"),
        },
        ...(IPRFileKey && checkedIPR && { IPR: IPRFileKey }),
        ipr_is_active: checkedIPR,
        ipr_is_complete: checkedIPRCompleted,
        is_teeth_visible: checkedIsTeethImageVisible,
        ...(vimeoRefinementValue && {
          refinement_video_id: vimeoRefinementValue,
        }),
        send_webhook: checkedWebhook,
        send_email: checkedEmail,
        ...(superImposedKey1 && { superimposed_1: superImposedKey1 }),
        ...(superImposedKey2 && { superimposed_2: superImposedKey2 }),
        ipr_before_step_number: beforeStepValue,
        update_freshsales_status: checkedUpdateCustomerStage,
        ...(selectedTPDesigner && { designer_name: selectedTPDesigner }),
        ...(selectedOutsourcedDesigner && { outsourced_designer: selectedOutsourcedDesigner }),
        outsourced_support_designer_id: (selectedOutsourcedDesigner && selectedOutsourcedSuportDesigner) ? selectedOutsourcedSuportDesigner : null,
        requires_redo: checkedRequireRedo,
        requires_opg: checkedRequireOpg,
        ...(selectedRequiredRedo && { redo_type: selectedRequiredRedo }),
        night_time_aligner: checkedNightTimeAligner ? 1 : 0,
        preferred: checkedPreferred ? 1 : 0,
        id: plan_id,
        plan_type: typeTp ? valuePlanType[0].value : undefined,
        operations_plan_type: typeTp ? operationsValuePlanType[0].value : undefined,
        hide_price: checkedHidePrice,
        hide_bundle: checkedHideBundles,
        bypass_early_bird: typeTp ? bypassEarlyBird : false,
        status: typeTp ? (valuePublishDraft[0].value == '' ? 'Draft' : valuePublishDraft[0].value) : 'Active',
        production_company_id: oemCompany.length ? oemCompany[0].value : null,
        stl_files_link: stlLink,
        tp_name: tpPlanName,
        type: tpType,
        user_email_sent: checkedEmail,
        protrusion: selectedCaseTypes.find(type => type.value == 'Protrusion') ? true : false,
        crowding: selectedCaseTypes.find(type => type.value == 'Crowding') ? true : false,
        spacing: selectedCaseTypes.find(type => type.value == 'Spacing') ? true : false,
        parent_id,
        level,
        last_step_before_mca: calendarId,
      };
      if (!createMode && typeTp && !checkedIPR && plan_id) { // on TP update
        axios.delete(`/admin/v1/users/${id}/smile-plan/${plan_id}/ipr-file/delete`, {
          data: {
            ipr: iPRFileName
          },
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }).then((res) => {
          console.log('AXIOS', res);
        })
      }
      let url = '';
      if(id) {
        if(plan_id) {
          url = `admin/v1/users/${id}/smile-plan/${plan_id}/update`;
        }
        if(createMode) {
          url = `admin/v1/users/${id}/smile-plan`;
        }
      }
      axios.post(url, bodyObj, {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      }).then((res) => {
        let planId = null;
        if(res.data.success) {
          planId = res.data.data.id;
        }
        let link = null;
        if(autoSaved || pdfDetails.isOpen == 2) {
          if(pdfDetails.isOpen == 2) {
            setPdfDetails({
              loading: false,
              isOpen: 0,
              generatePDFClicked: false,
            });
          }
          if(createMode) {
            if(typeTp) {
              link = `/admin/users/${id}/dashboard/yoursmileplan/${planId}/update`;
            } else {
              link = `/admin/users/${id}/dashboard/yoursmileplan/${planId}/refinement/edit`;
            }
          }
        } else {
          link = `/admin/users/${id}/dashboard`;
        }
        if(link) {
          history.push(link);
          window.location.reload();
        } else {
          fetchData(false);
        }
        setPopupActive(false);
      }).catch((err) => {
        console.log(err)
      });
    }
  }

  function switchSelectedWearTime(param) {
    switch (param) {
      case "1-week":
        return 4;
      case "2-week":
        return 2;
      case "10-day":
        return 3;
      default:
        return 0;
    }
  }

  function getWearTime(param) {
    switch (param) {
      case 7:
        return "1-week";
      case 14:
        return "2-week";
      case 10:
        return "10-day";
      default:
        return '';
    }
  }

  const save = () => {
    setAutoSave(prev => prev + 1);
  }

  useEffect(() => {
    setValueLengthOfTreatment(
      String(
        Math.ceil(
          Math.max(valueNumberAlignersLower, valueNumberAlignersUpper) /
          switchSelectedWearTime(selectedAlignerWearTime)
        )
      )
    );
  }, [valueNumberAlignersLower, valueNumberAlignersUpper, selectedAlignerWearTime])

  useEffect(() => {
    if(typeTp) {
      if(createMode) {
        let opType = {
          label: 'light',
          value: 'light',
        };
        setValuePlanType(prev => {
          return [...prev, opType]
        });
        setOperationsValuePlanType(prev => {
          return [...prev, opType]
        });
      } else {
        axios.get(`admin/v1/users/${id}/smile-plan`, {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }).then((result) => {
          setSelectResultData(result.data.data);
        })
      }
    }
  }, []);

  useEffect(() => {
    void getUser();
  }, []);

  useEffect(() => {
    if (!createMode) {
      axios
        .get("admin/v1/users/" + id + "/profile", {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        })
        .then((result) => {
          result?.data?.data?.user?.freshsales_contact_id &&
            result?.data?.data?.city &&
            setCity(result.data.data.city);
          result?.data?.data?.country &&
            setCountry(result.data.data.country);
          result?.data?.data?.user?.full_name &&
            setFullName(result.data.data.user.full_name);
          setIsOutsourced(result?.data?.data?.outsourcedDesigner);
        })
        .catch((err) => console.log(err));
    } else {
      if(!typeTp && plan_id){
        let config = {
          headers: {
            Authorization: "Bearer " + Cookie.get("token"),
          },
        }
        axios.get(`admin/v1/users/${id}/smile-plan/${plan_id}`, config).then((response) => {
          setLevel(response.data.data.level + 1);
          setSelectedTPDesigner(response?.data?.data?.designer_name);
        }).catch((err) => {
          console.log(err);
        })
      }
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get("admin/v1/oem_companies", {
        headers: {
          Authorization: "Bearer " + Cookie.get("token"),
        },
      })
      .then((res) => {
        let companyOptions = res.data.data.map((company) => ({
          label: company.full_name,
          value: company.id,
        }));
        companyOptions.unshift({ label: "None", value: null });
        setOemOptions(companyOptions);
        fetchData(true,companyOptions);
      });
  }, []);

  useEffect(() => {
    if (upperStepsError || lowerStepsError) {
      const element = document.getElementById('upperStepsError');
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [upperStepsError, lowerStepsError])

  useEffect(() => {
    if(autoSave > 0) {
      handleSave(true);
    }
  }, [autoSave])

  useEffect(() => {
    if (beforeStepValueArray.length) {
      let procedureDescription = [];
      let procedureDescriptions = [];
      let descriptions = [];
      beforeStepValueArray.forEach(procedure => {
        if(procedure && procedure.descriptions) {
          if(procedure.descriptions.length) {
            procedure.descriptions.forEach(description => {
              if(description.comment && !descriptions.includes(description.comment)) {
                descriptions.push(description);
              }
            })
          }
          Object.keys(procedure).filter((key) => {
            return procedure[key] == 1 && optionsProceduresHashMap[key] !== undefined;
          }).map((key) => {
            if(!procedureDescription.includes(optionsProceduresHashMap[key])){
              procedureDescription.push(optionsProceduresHashMap[key]);
              let proc = procDescriptions.find(p => p.value == key);
              if(proc){
                procedureDescriptions.push(proc);
                // If there is a buttons procedure, show elastics and buttons in the description
                if(key == 'buttons') {
                  procedureDescriptions.push(procDescriptions.find(p => p.value == 'elastics'));
                }
              }
            }
          });
        }
      });
      if(procedureDescriptions.length) {
        procedureDescriptions.sort(function(a, b) { 
          return a.id - b.id;
        });
      }
      setProcedures(procedureDescriptions);
      if(descriptions.length) {
        setComments({
          dentalWork: descriptions.filter(desc => desc.type == 'Dental Checkup').map(desc => desc.comment).join(', '),
          extraction: descriptions.filter(desc => desc.type == 'Extraction').map(desc => desc.comment).join(', '),
        });
      }
    }
  }, [beforeStepValueArray]);

  useEffect(() => {
    if(selectResultData.length) {
      setPlanSelected(selectResultData.find(plan => plan.id === parseInt(plan_id)));
    }
  }, [selectResultData])

  useEffect(() => {
    if(isOutsourced) {
      let newDesigners = [
        { value: 'Kareem Mazloum', label: 'Kareem Mazloum' },
        { value: 'Elissa Mokh', label: 'Elissa Mokh' },
        { value: 'Jana Al Houssaini', label: 'Jana Al Houssaini' },
        { value: 'Hasan Daher', label: 'Hasan Daher' },
        { value: 'George Hakim', label: 'George Hakim' },
      ];
      let designer = newDesigners.find(des => des.label === selectedTPDesigner);
      let designers = [
        { label: "Ghady Freiha", value: "Ghady Freiha" },
        { label: "Karine Shhaitly", value: "Karine Shhaitly" },
        { label: "Tania Abi Farraj", value: "Tania Abi Farraj" },
        { value: 'Jana Kabalan', label: 'Jana Kabalan' },
        { value: 'Ghadir Ismail', label: 'Ghadir Ismail' },
        { value: 'Zeina Rafeh', label: 'Zeina Rafeh' },
        { label: "No One", value: "No One" },
      ];
      if(designer) {
        designers.push(designer);
      }
      setOptionsTPDesigner(designers);
    }
  }, [isOutsourced, selectedTPDesigner])

  return (
    loading ?
      <Loading /> :
      <div>
        <ToastContainer />
        <AppProvider>
          <Page>
            <UserHeader pageTitle={pageTitle} status={status} userId={userId} clinicStatus={clinicStatus} clinicName={clinicName} planType={planType} />
            <br />
            <h1 className="tp-page-header">Treatment Plans</h1>
            <br />
            {!typeTp ?
              <>
                <RtpMca
                  handleSelectTpType={handleSelectTpType}
                  tpType={tpType}
                  handleCalendarStepChange={handleCalendarStepChange}
                  calendarId={calendarId}
                  prevTpSteps={prevTpSteps}
                  lastStepBeforeMcaRequired={lastStepBeforeMcaRequired}
                  tpOptions={tpOptions}
                />
                <br />
              </> :
              null
            }
            <TpActionButtons
              createMode={createMode}
              addTp={addTp}
              handleAddRtp={handleAddRtp}
              typeTp={typeTp}
            />
            <Profile
              fullName={fullName}
              id={id}
              country={country}
              city={city}
            />
            <br />
            <div className="tp-nickname-container">
              {createMode && typeTp ? <TextField
                value={'Initial Treatment'}
                readOnly
                disabled
              /> : null}
              <TextField
                placeholder="Add Treatment Plan Nickname"
                value={tpPlanName}
                onChange={(value) => {
                  setTpPlanName(value);
                }}
              />
            </div>
            {!createMode ?
              <>
                {typeTp ?
                  <select
                    value={plan_id}
                    style={{
                      width: "100%",
                      padding: "8px",
                      borderRadius: "5px",
                      border: "1px lightgray solid",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    onChange={(e) => selectTp(e)}
                  >
                    {selectResultData &&
                      selectResultData.map((val, key) => {
                        if (val.type == "tp" || val.type == null) {
                          return (
                            <option key={key} value={val.id}>
                              {val?.name}
                            </option>
                          );
                        }
                      })}
                  </select> :
                  <br />
                }
                <select
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "5px",
                    border: "1px lightgray solid",
                    marginBottom: "10px",
                  }}
                  onChange={(e) => handleSelectRouting(e.target.value)}
                >
                  {childItems && childItems.length > 0 && (
                    <option>-- Select Refinement Plan --</option>
                  )}
                  {childItems && childItems.length > 0 ? (
                    childItems.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item?.name}
                        </option>
                      );
                    })
                  ) : (
                    <option>No other Treatment Plans exist</option>
                  )}
                </select>
              </> :
              <br />
            }
              <Modal
                open={pdfDetails.isOpen}
                large
                primaryAction={{
                  content: "Generate Pdf",
                  loading: pdfDetails.loading,
                  onAction: generatePdf,
                }}
                secondaryActions={[
                  {
                    content: "Close",
                    disabled: pdfDetails.generatePDFClicked,
                    onAction: () => {
                      setPdfDetails(prev => ({
                        ...prev,
                        isOpen: 0,
                      }));
                    },
                  },
                ]}
              >
              <Modal.Section>
                <div className="tp-pdf-modal" id='tp-pdf-modal-preview'>
                  <div ref={pdfRef} className="tp-pdf-container">
                    <TreatmentPDFEn
                      user={fullName}
                      selectedCaseTypes={selectedCaseTypes}
                      procedures={procedures}
                      id={id}
                      cases={cases}
                      valueTreatmentDescription={valueTreatmentDescriptionEn ? ReactHtmlParser(valueTreatmentDescriptionEn.toString("html")) : ''}
                      comments={comments}
                      valueLengthOfTreatment={valueLengthOfTreatment}
                      selectedAlignerWearTime={selectedAlignerWearTime}
                      valueNumberAlignersUpper={valueNumberAlignersUpper}
                      valueNumberAlignersLower={valueNumberAlignersLower}
                      type={tpType}
                      beforeStepValueArray={beforeStepValueArray}
                      createdAt={createdAt}
                      name={treatmentStage}
                      checkedIsTeethImageVisible={checkedIsTeethImageVisible}
                      firstAppointmentNumber={firstAppointmentNumber}
                      optionsProceduresHashMap={proceduresHashMap}
                      checkedRequireRedo={checkedRequireRedo}
                    />
                  </div>
                  <br />
                  <div ref={pdfRef2} className="tp-pdf-container">
                    <TreatmentPDFAr
                      user={fullName}
                      selectedCaseTypes={selectedCaseTypes}
                      procedures={procedures}
                      id={id}
                      cases={cases}
                      valueTreatmentDescription={valueTreatmentDescriptionAr ? ReactHtmlParser(valueTreatmentDescriptionAr.toString("html")) : ''}
                      comments={comments}
                      valueLengthOfTreatment={valueLengthOfTreatment}
                      selectedAlignerWearTime={selectedAlignerWearTime}
                      valueNumberAlignersUpper={valueNumberAlignersUpper}
                      valueNumberAlignersLower={valueNumberAlignersLower}
                      type={tpType}
                      beforeStepValueArray={beforeStepValueArray}
                      createdAt={createdAt}
                      name={treatmentStage}
                      checkedIsTeethImageVisible={checkedIsTeethImageVisible}
                      firstAppointmentNumber={firstAppointmentNumber}
                      optionsProceduresHashMap={proceduresHashMap}
                      checkedRequireRedo={checkedRequireRedo}
                    />
                  </div>
                </div>
              </Modal.Section>
            </Modal>
            <FormLayout>
              <TPAccordion title='Treatment Plan Details'>
                <TreatmentPlan
                  planSelected={planSelected}
                  valueTreatmentDescriptionEn={valueTreatmentDescriptionEn}
                  valueTreatmentDescriptionAr={valueTreatmentDescriptionAr}
                  valueNumberAlignersUpper={valueNumberAlignersUpper}
                  valueNumberAlignersLower={valueNumberAlignersLower}
                  valueLengthOfTreatment={valueLengthOfTreatment}
                  selectedAlignerWearTime={selectedAlignerWearTime}
                  checkedIsTeethImageVisible={checkedIsTeethImageVisible}
                  handleChangeUpperAlignersNb={handleChangeUpperAlignersNb}
                  handleChangeLowerAlignersNb={handleChangeLowerAlignersNb}
                  upperStepsError={upperStepsError}
                  lowerStepsError={lowerStepsError}
                  handleChangeUpperStepsError={handleChangeUpperStepsError}
                  handleChangeLowerStepsError={handleChangeLowerStepsError}
                  handleTreatmentDescriptionEn={handleTreatmentDescriptionEn}
                  handleTreatmentDescriptionAr={handleTreatmentDescriptionAr}
                  handleCheckedIsTeethImageVisible={handleCheckedIsTeethImageVisible}
                  invalidPlans={invalidPlans}
                  handeChangeTreatmentLength={handeChangeTreatmentLength}
                  handeChangeSelectedWearTime={handeChangeSelectedWearTime}
                  valuePlanType={valuePlanType}
                  handleValuePlanType={handleValuePlanType}
                  handleOperationsValuePlanType={handleOperationsValuePlanType}
                  operationsValuePlanType={operationsValuePlanType}
                  TPLink={TPLink}
                  setvalueTreatmentDescriptionEn={setvalueTreatmentDescriptionEn}
                  setvalueTreatmentDescriptionAr={setvalueTreatmentDescriptionAr}
                  createMode={createMode}
                  handleSave={checkForDispatches}
                  id={id}
                  firstAppointmentNumber={firstAppointmentNumber}
                  handleSelectChange={handleSelectChange}
                  selectedCaseTypes={selectedCaseTypes}
                  disabled={!valueNumberAlignersUpper || !valueNumberAlignersLower}
                />
              </TPAccordion>
              <TPAccordion title='Treatment Plan Designer' defaultExpanded={!createMode} subTitle={oldDesignerName}>
                <TpDesigner
                  optionsTPDesigner={optionsTPDesigner}
                  handleSelectChangeTPDesigner={handleSelectChangeTPDesigner}
                  selectedTPDesigner={selectedTPDesigner}
                  disabled={createMode}
                />
              </TPAccordion>
              <TPAccordion title='Outsourced Plan Designer' defaultExpanded={!createMode}>
                <TpDesigner
                  optionsTPDesigner={optionsoutsourcedDesigner}
                  handleSelectChangeTPDesigner={handleSelectChangeOutSourcedDesigner}
                  selectedTPDesigner={selectedOutsourcedDesigner}
                  disabled={createMode}
                />
              </TPAccordion>
              {
                selectedOutsourcedDesigner ?
                <TPAccordion title='Outsourced Support Designer' defaultExpanded={!createMode}>
                  <TpDesigner
                    optionsTPDesigner={outsourcedSuportDesigners}
                    handleSelectChangeTPDesigner={handleChangeOutsourcedSupportDesigner}
                    selectedTPDesigner={selectedOutsourcedSuportDesigner}
                    disabled={createMode}
                  />
                </TPAccordion> :
                null
              }
              <TPAccordion title='Appointments and Procedures' defaultExpanded={!createMode}>
                <IPR
                  createMode={createMode}
                  iprFileRequired={iprFileRequired}
                  handeChangeCheckedIPR={handeChangeCheckedIPR}
                  handeChangeCheckedIPRCompleted={handleChangeIPRCompleted}
                  handeChangeIPRkeyFile={handeChangeIPRkeyFile}
                  handeChangeIPRFileName={handeChangeIPRFileName}
                  iPRFileName={iPRFileName}
                  isIprChecked={checkedIPR}
                  isIprCheckedCompleted={checkedIPRCompleted}
                  IPRLink={IPRLink}
                  handleChangeBeforeStepNumber={handleChangeBeforeStepNumber}
                  beforeStepValue={beforeStepValue}
                  proceduresIprSheet={proceduresIprSheet}
                  userId={userId}
                  handleChangeBeforeStepNumberArray={handleChangeBeforeStepNumberArray}
                  beforeStepValueArray={beforeStepValueArray}
                  showIpr={true}
                  dataId={plan_id}
                  fullName={fullName}
                  treatmentStage={createMode ? '' : (typeTp ? planSelected?.name : treatmentStage)}
                  loading={loading}
                  checkedRequireOpg={checkedRequireOpg}
                  handleChangeRequireOpg={handleChangeRequireOpg}
                  checkedRequireRedo={checkedRequireRedo}
                  handleChangeRequireRedo={handleChangeRequireRedo}
                  handleSelectChangeRequiredRedoSelection={handleSelectChangeRequiredRedoSelection}
                  selectedRequiredRedo={selectedRequiredRedo}
                  autoSave={() => {openPDF(2)}}
                  disabled={createMode || !valueNumberAlignersUpper || !valueNumberAlignersLower}
                  firstAppointmentNumber={firstAppointmentNumber}
                  setFirstAppointmentNumber={setFirstAppointmentNumber}
                  disableSave={!valueNumberAlignersUpper || !valueNumberAlignersLower}
                />
              </TPAccordion>
              <TPAccordion title='3D Simulation' defaultExpanded={!createMode}>
                <ThreeDSimulation
                  selectedOption={selectedOption}
                  handleSelectChangeOption={handleSelectChangeOption}
                  oem3DLink={oem3DLink}
                  shareable3DLink={shareable3DLink}
                  generateShareableLink={generateShareableLink}
                  loading3D={loading3D}
                  setOem3DLink={setOem3DLink}
                  vimeo1Value={vimeo1Value}
                  vimeo2Value={vimeo2Value}
                  vimeoRefinementValue={vimeoRefinementValue}
                  vimeo1Error={vimeo1Error}
                  vimeo2Error={vimeo2Error}
                  vimeoRefinementError={vimeoRefinementError}
                  handleChangeVimeo1Error={handleChangeVimeo1Error}
                  handleChangeVimeo2Error={handleChangeVimeo2Error}
                  handleChangeRefinementError={handleChangeRefinementError}
                  handleChangeVimeo1Value={handleChangeVimeo1Value}
                  handleChangeVimeo2Value={handleChangeVimeo2Value}
                  handleChangeRefinementValue={handleChangeRefinementValue}
                  handleChangeArrayElements={handleChangeArrayElements}
                  numberOf3dFiles={numberOf3dFiles}
                  setNumberOf3dFiles={setNumberOf3dFiles}
                  displayable3dFiles={displayable3dFiles}
                  setDisplayable3dFiles={setDisplayable3dFiles}
                  dataId={plan_id}
                  id={id}
                  yourSmilePlanData={yourSmilePlanData}
                  handleLearnMore={handleLearnMore}
                  setHandleLearnMore={setHandleLearnMore}
                  createMode={createMode}
                  autoSave={save}
                  disabled={createMode}
                />
              </TPAccordion>
              <TPAccordion title='Superimposed Images' defaultExpanded={!createMode}>
                <SuperimposedImages
                  handleChangeSuperImposedKey1={handleChangeSuperImposedKey1}
                  handleChangeSuperImposedKey2={handleChangeSuperImposedKey2}
                  imagePreview1={superImposedImagePreview1}
                  setImagePreview1={setSuperImposedImagePreview1}
                  imagePreview2={superImposedImagePreview2}
                  setImagePreview2={setSuperImposedImagePreview2}
                  dataId={plan_id}
                  autoSave={save}
                  disabled={createMode}
                />
              </TPAccordion>
              <TPAccordion title='Comments' defaultExpanded={!createMode}>
                <Comments
                  plan_id={plan_id}
                  showComment={!createMode}
                  save={handleSave}
                  disabled={createMode}
                />
              </TPAccordion>
              <TpApproval
                labApproved={labApproved}
                disableButtonApproval={disableButtonApproval}
                doctorApproved={doctorApproved}
                approvedFunc={approvedFunc}
                labApprovedDate={labApprovedDate}
                doctorApprovedDate={doctorApprovedDate}
                updateMode={!createMode && tpType == 'tp'}
              />
              <TPAccordion title='Actions and Notifications' defaultExpanded={!createMode}>
                <div className="actions-notifications-container">
                  <Checkbox
                    label="Notify Sales / Notify for Approval (Send Slack Notification)"
                    checked={checkedWebhook}
                    onChange={handleChangeWebhook}
                    helpText="If status is Draft, message is sent to #customer_tp_draft_uploaded. If status is Pending, message is sent to #customer_treatment_plan_approval. If status is Active, message is sent to #customer_treatment_plan_ready."
                    disabled={createMode}
                  />
                  <Checkbox
                    label="Update Customer Stage (On CRM)"
                    checked={checkedUpdateCustomerStage}
                    onChange={handleChangeUpdateCustomerStage}
                    helpText="Only use this checkbox when uploading the final version of a Treatment Plan. This will affect the stage of the customer on the CRM."
                    disabled={createMode}
                  />
                  <Checkbox
                    label="Send Email To Customer"
                    checked={checkedEmail}
                    onChange={handleChangeEmail}
                    disabled={createMode || disableCheckedEmail}
                  />
                  <Checkbox
                    label="Night-time Aligner Treatment "
                    checked={checkedNightTimeAligner}
                    onChange={handleChangeCheckedNightTimeAligner}
                    disabled={createMode}
                  />
                  <Checkbox
                    label="Hide Price From User's Profile"
                    checked={checkedHidePrice}
                    onChange={handleHidePrice}
                    disabled={createMode}
                  />
                  <Checkbox
                    label="Hide Early Bird Discount From User's Profile"
                    checked={bypassEarlyBird}
                    onChange={handleChangeBypassEarlyBird}
                    disabled={createMode}
                  />
                  <Checkbox
                    label="Hide Bundles From User's Profile"
                    checked={checkedHideBundles}
                    onChange={handleChangeHideBundles}
                    disabled={createMode}
                  />
                </div>
              </TPAccordion>
              <Card sectioned>
                <Checkbox
                  label="Mark This Treatment As Preferred"
                  checked={checkedPreferred}
                  onChange={handleChangeCheckedPreferred}
                  disabled={createMode}
                />
              </Card>
              <div>
                <TextStyle variation="strong">STL Files Link</TextStyle>
                <TextField
                  value={stlLink}
                  onChange={setStlLink}
                  type="text"
                />
                <TextStyle variation="strong">Production Company</TextStyle>
                <Select2
                  onChange={(newValue) => handleProductionCompany(newValue)}
                  values={oemCompany}
                  options={oemOptions}
                />
              </div>
              <TextStyle variation="strong">Status</TextStyle>
              <Select2
                disabled={createMode}
                onChange={(newValue) => handlePublishDraft(newValue)}
                values={valuePublishDraft}
                options={optionsType}
              />
              <PageActions
                primaryAction={{
                  content: "Save TP",
                  onClick: () => checkForDispatches(1),
                }}
                secondaryActions={{
                  content: "Delete",
                  onClick: () => setOpenActionModal(true),
                }}
              />
            </FormLayout>
            <WarningRegenerateDispatches open={openWarning} setOpen={setOpenWarning} handleSave={() => openPDF(2)} warningMessage={warningMessage}/>
            <Modal open={popupActive} loading={true}></Modal>
          </Page>
        </AppProvider>
        <Modal
          open={openActionModal}
          onClose={() => setOpenActionModal(false)}
          title="Action"
          primaryAction={{
            content: 'No',
            onAction: () => setOpenActionModal(false),
          }}
          secondaryActions={[
            {
              content: 'Yes',
              onAction: handleDelete,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>Are you sure you want to delete this treatment plan?</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </div>
  );
}

export default React.memo(SmilePlan);
