import classnames from "classnames";
import React from 'react'
import {
  MobileHamburgerMajor,
  BlogMajor,
  InventoryMajor,
  RecentSearchesMajor,
  CapturePaymentMinor,
  SoftPackMajor, ShipmentMajor
} from '@shopify/polaris-icons';
  import {
    Icon,
  } from "@shopify/polaris";
import "./UserLayout.css"
import GuestHeader from "./guest-header";
import CaseDiscussion from "../CaseDiscussion";

export default function GuestLayout ({ pageTitle, scrollSmoothly, activeId, children, renderSection, ontoggleNav, openSideNav }) {
    const mobile = window.matchMedia("(max-width: 768px)").matches;
    const linkColor = "#FF2C46";
    const queryParams = new URLSearchParams(window.location.search);
    const openChatParam = queryParams.get('openChat');

    return (
        <div className="HeaderStickyResp" style={{ transition:0.5,marginLeft:openSideNav? '195px':'55px', top:"55px", minWidth:"80%"}}>
            <GuestHeader pageTitle={pageTitle}/>
            <div>
              <CaseDiscussion status={openChatParam === 'true'} />
            <div className={classnames({ blur: mobile && openSideNav })} />
            <div
                className={classnames(
                "sidenav",
                { sidenavOpen: openSideNav },
                { sidenavClose: !openSideNav }
                )}
            >
                <div style={{padding:'5px 20px', cursor:"pointer", display:"flex", flexDirection:"row"}} onClick={ontoggleNav}>{renderSection("USER SECTIONS", true)}<Icon
                    source={MobileHamburgerMajor}
                    onClick={ontoggleNav}
                    className="burgermenu"
                    style={{color:"white"}}
                  /></div>
                <br></br>
                <br></br>
                <a style={activeId == 'shipping' ? { color: linkColor, fontWeight:500} : {}} className="shipping" onClick={() => scrollSmoothly('shipping')}><span className="iconsDiv"><Icon source={InventoryMajor} color="base"/><span>{renderSection('Shipping')}</span></span></a>
              <a
                style={
                  activeId == "paymentdetails"
                    ? { color: linkColor, fontWeight: 500 }
                    : {}
                }
                className="paymentdetails"
                onClick={() => scrollSmoothly("paymentdetails")}
              >
              <span className="iconsDiv">
                <Icon source={ShipmentMajor} color="base" />
                <span>{renderSection("Upcoming Dispatches")}</span>
              </span>
              </a>
                <a style={activeId == 'trackings' ? { color: linkColor, fontWeight:500} : {}} className="trackings" onClick={() => scrollSmoothly('trackings')}><span className="iconsDiv"><Icon source={SoftPackMajor} color="base"/><span>{renderSection('Trackings')}</span></span></a>
                <a style={activeId == 'payments' ? { color: linkColor, fontWeight:500} : {}} className="payments" onClick={() => scrollSmoothly('payments')}><span className="iconsDiv"><Icon source={CapturePaymentMinor} color="base"/><span>{renderSection('Transactions')}</span></span></a>
                <a style={activeId === 'tickets' ? { color: linkColor, fontWeight:500} : {}} className="tickets" onClick={() => scrollSmoothly('tickets')}><span className="iconsDiv"><Icon source={BlogMajor} color="base"/><span>{renderSection('Tickets')}</span></span></a>
                <a style={activeId == 'history' ? { color: linkColor, fontWeight:500} : {}} className="history" onClick={() => scrollSmoothly('history')}><span className="iconsDiv"><Icon source={RecentSearchesMajor} color="base"/><span>{renderSection('History')}</span></span></a>
            </div>

            <div
                style={{padding:"0px 20px"}}
                className={classnames(
                "main",
                { mainShrink: openSideNav },
                { mainExpand: !openSideNav },
                { noscroll: mobile && openSideNav }
                )}
            >
             {children}
            </div>
            </div>
      </div>
    )
}