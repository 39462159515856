import { ExportMinor } from "@shopify/polaris-icons";
import { Card, DataTable, Modal, Page, TextStyle } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import axios from "../axios";
import Cookie from "js-cookie";
import Pagination from "@material-ui/lab/Pagination";
import { GeneralFormats } from "../util/constants";
import moment from "moment";

function SettleAllReports() {
  const [popupActive, setPopupActive] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const dateFormat = GeneralFormats.dateFormat;

  const handleChange = (event, value) => {
    setPage(value);
  };

  const exportSettleAllDiscountsReports = async () => {
    try {
      setLoading(true);
      const date = moment().format("DD-MM-YYYY");
      const response = await axios({
        url: `/admin/v1/reports/settle-all-discounts-export`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
        responseType: "blob",
      });
      if (!response) {
        return;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Settle All Discounts Report ${date}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log("Error in exportSettleAllDiscountsReports", err);
    } finally {
      setLoading(false);
    }
  };
  const fetchSettleAllDiscountsReports = async () => {
    try {
      setPopupActive(true);
      const result = await axios.get(
        `/admin/v1/reports/settle-all-discounts?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        },
      );
      if (result?.data?.success) {
        setTotalPages(result?.data?.total_pages);
        setItems(
          result?.data?.data?.map((report) => [
            report.userId,
            report.name,
            report.email,
            report.currency,
            report.settleAllAmount,
            report.totalAmount,
            report.settleAllDiscount,
            report.numberOfPaymentsRemaining,
            report.originalNumberOfPayments,
            report.settleAllPaymentsQuantity,
            moment(report.settleAllDate).format(dateFormat),
          ]),
        );
      }
    } catch (err) {
      console.log("Error in fetchSettleAllDiscountsReports", err);
    } finally {
      setPopupActive(false);
    }
  };

  useEffect(() => {
    void fetchSettleAllDiscountsReports();
  }, [page]);

  return (
    <Page
      fullWidth
      title="Settle All Discounts Report"
      breadcrumbs={[{ content: "List Of Reports", url: "/admin/reports" }]}
      secondaryActions={[
        {
          content: "Export",
          icon: ExportMinor,
          onAction: exportSettleAllDiscountsReports,
          loading,
        },
      ]}
    >
      <Card sectioned>
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
          ]}
          headings={[
            <TextStyle variation="strong">User Id</TextStyle>,
            <TextStyle variation="strong">Name</TextStyle>,
            <TextStyle variation="strong">Email</TextStyle>,
            <TextStyle variation="strong">Currency</TextStyle>,
            <TextStyle variation="strong">Settle All Amount</TextStyle>,
            <TextStyle variation="strong">Total Amount</TextStyle>,
            <TextStyle variation="strong">Settle All Discount (%)</TextStyle>,
            <TextStyle variation="strong">
              Number Of Payments Remaining
            </TextStyle>,
            <TextStyle variation="strong">
              Original Number Of Payments
            </TextStyle>,
            <TextStyle variation="strong">
              Settle All Payments Quantity
            </TextStyle>,
            <TextStyle variation="strong">Settle All Date</TextStyle>,
          ]}
          rows={items}
          sortable={[
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ]}
          verticalAlign="center"
          hideScrollIndicator
        />
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChange}
              size="large"
            />
          </div>
        )}
      </Card>
      <Modal open={popupActive} loading={true}></Modal>
    </Page>
  );
}

export default SettleAllReports;
